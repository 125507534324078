
import {
    defineComponent, Ref, ref
} from 'vue';
import propertyBreadCrumb from '@/components/view/pm/bread-crumb';
import {
    listPagination, RequestData, getSearch, ListHeaderItem
} from '@/components/common/list';
import router from '@/router';
import { community } from '@/data';
import addButton from '@/components/common/add-button/index.vue';

const searchKeyList = [{
    name: 'Content',
    label: WordList.TabelMessageBoxMessage
}];

const headers: Array<ListHeaderItem> = [{
    name: 'Title',
    label: WordList.MessageListTanleTitle
}, {
    name: 'Content',
    label: WordList.TabelMessageBoxMessage,
    type: 'customize'
}, {
    name: 'NickNames',
    label: WordList.TabelMessageBoxReceiver
}, {
    name: 'Type',
    label: WordList.ProperAllTextTypeOfReceiver
}, {
    name: 'CreateTime',
    label: WordList.TabelMessageBoxCreateTime
}];
interface MessageType {
    Title: string;
    Type: string;
    NickNames: string;
    Message: string;
    CreateTime: string;
}
export default defineComponent({
    components: {
        propertyBreadCrumb,
        listPagination,
        addButton
    },
    setup() {
        const breadHeaders = [{ label: WordList.ProperAllTextGroupMessages }];
        const listRequestData: Ref<RequestData> = ref({
            url: `v3/web/${community.ProjectType.value}/message/getList`,
            param: getSearch({
                searchKey: 'Content',
                searchValue: ''
            })
        });
        const update = ref(0);
        const saveParamInPath = ref(false);
        const searchList = () => {
            saveParamInPath.value = true;
            update.value += 1;
        };
        const deleteUrl = `v3/web/${community.ProjectType.value}/message/del`;

        const info = (message: MessageType) => {
            localStorage.setItem('messageInfo', JSON.stringify(message));
            router.push('/propertyIndex/messageInfo');
        };

        const add = () => {
            router.push('/propertyIndex/messageAdd');
        };
        return {
            breadHeaders,
            listRequestData,
            searchKeyList,
            headers,
            update,
            deleteUrl,
            info,
            add,
            saveParamInPath,
            searchList
        };
    }
});
